import React from "react";

// https://ourworldindata.org/how-to-embed-charts


// core components

function CovidMap() {

  return (
      <iframe src="https://ourworldindata.org/grapher/total-cases-covid-19?tab=map" width="100%" height="600px"></iframe>
  );
}

export default CovidMap;
