/*eslint-disable*/
import React from "react";
import CountUp from 'react-countup';
import {
  Container,
  Row,
  Col
} from "reactstrap";

function DynamicCounter() {
  return (
          <Container>
            <Row>
              <Col className="col-lg-6">
                <p className="mb-5"><img src={require("assets/img/logistic_4.jpg")} alt="Image" className="img-fluid" /></p>
              </Col>
              <Col className="col-lg-5 ml-auto">
                <h2 className="site-section-heading mb-3 font-secondary text-uppercase">High quality healthcare product</h2>
                <p className="mb-5">We provide product throughout the world </p>

                <Row>
                  <Col className="col-lg-6">
                    <Row>Total supplement</Row>
                    <Row className="number"><CountUp delay={3} end={120400} /></Row>
                  </Col>
                  <Col className="col-lg-6">
                    <Row>Number of ships</Row>
                    <Row className="number"><CountUp delay={3} end={10} /></Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
  );
}

export default DynamicCounter;
