/*eslint-disable*/
import React from "react";
import Slide from 'react-reveal/Slide';
import iconSet from "assets/fonts/icomoon/selection.json";
import IcomoonReact from "icomoon-react";

function LogisticScope() {
  return (
      <Slide>
        <div className="container" id="logistic-scope">
          <div className="row mb-5">
            <div className="col-md-12" data-aos="fade">
              <h2 className="site-section-heading text-center text-uppercase">Service Scope</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12 mb-4" data-aos="fade-up" data-aos-delay="100">

              <h3 className="d-flex align-items-center">
                <IcomoonReact iconSet={iconSet} color="success" size={30} icon="timer" />
                Lead Time Based Services
              </h3>
              <p>Where more than one factor comes into play, we are creating international logistics solutions to
                support medical supply chains around the world with the transportation of medical suppliers, personal
                protective equipment (PPE) and other essential items.</p>
              <ul>
                <li>Express - 1-2 days for urgent cargo</li>
                <li>Standard - 3-5 days, flexible choice</li>
                <li>Economy - 5-7 days, maximizing cost effectiveness</li>
              </ul>
            </div>

            <div className="col-md-12 col-lg-12 mb-4" data-aos="fade-up" data-aos-delay="200">
              <h3 className="d-flex align-items-center">
                <IcomoonReact iconSet={iconSet} color="success" size={30} icon="payment" />
                Special Services, Balancing Capacity, Speed and Cost
              </h3>
              <p>When lead time is the determining factor, you can choose from: Charter</p>
            </div>

            <div className="col-md-12 col-lg-12 mb-4" data-aos="fade-up" data-aos-delay="200">

              <h3 className="d-flex align-items-center">
                <IcomoonReact iconSet={iconSet} color="success" size={30} icon="truck" />
                Project Cargo
              </h3>
              <p>Our dedicated Project Cargo Team has deep experience in all modes of transport. They, in turn, can
                draw on local hands-on expertise to handle the most complex project cargo, from concept, through
                planning to final execution.</p>
              <p>Our global partnerships mean we can arrange charter flights to where your cargo needs to be. And we
                can solve issues of capacity, oversized or heavy cargo Sea and Air.</p>
              <p>Offering the combined benefits of cost effectiveness with faster lead times than ocean freight Air
                and Truck.</p>
            </div>

            <div className="col-md-12 col-lg-12 mb-4" data-aos="fade-up" data-aos-delay="200">

              <h3 className="d-flex align-items-center">
                <IcomoonReact iconSet={iconSet} color="success" size={30} icon="legal" />
                Customs Clearance
              </h3>
              <p>E-Fastcare offer a comprehensive customs clearance services providing you with tariff advice and
                consultation, assistance with tariff applications to help you to avoid unnecessary costs and
                delays.</p>
            </div>

            <div className="col-md-12 col-lg-12 mb-4" data-aos="fade-up" data-aos-delay="200">
              <h3 className="d-flex align-items-center">
                <IcomoonReact iconSet={iconSet} color="success" size={30} icon="store_mall_directory" />
                Warehousing
              </h3>
              <p>We provide bonded and highly specialized warehousing as part of an integrated clean room or
                temperature controlled supply chain. And, of course, a whole range of value added services for
                manufacturers and retailers.</p>
            </div>

            <div className="col-md-12 col-lg-12 mb-4" data-aos="fade-up" data-aos-delay="200">
              <h3 className="d-flex align-items-center">
                <IcomoonReact iconSet={iconSet} color="success" size={30} icon="call_split" />
                Distribution
              </h3>
              <p>E-Fastcare global network of logistics bases can help optimize your supply chain spanning 40
                countries. Tailored services and a single point of contact, with high levels of visibility. We can
                integrate your operations, including the sourcing and management of external service providers, across
                material product collection, warehousing, value added services, distribution and crossdocking.</p>
            </div>

            <div className="col-md-12 col-lg-12 mb-4" data-aos="fade-up" data-aos-delay="200">
              <h3 className="d-flex align-items-center">
                <IcomoonReact iconSet={iconSet} color="success" size={30} icon="cart-plus" />
                Value Added Services
              </h3>
              <h5>Kitting and Assembly</h5>
              <p>By delaying production-ready, or customer ready, kits and light assemblies until they are needed, we
                give you increased flexibility over your inventory. </p>
              <h5>Labelling</h5>
              <p>We can apply customized labels for different markets, or add last minute swing tags, bar codes and
                price stickers to support local promotions.</p>
              <h5>Pre-Inspection</h5>
              <p>Maintaining the highest quality standards, we offers on-site inspection of inbound and outbound goods
                and components, reducing wastage, protecting your brand.</p>
              <h5>Pre-Installation</h5>
              <p>Managing pre-installation work such as software installation, configuration and testing on a wide
                range of products including copiers, printers, cell-phones, and other electronics, as well as battery
                charging for the automotive and manufacturing industries.</p>
            </div>

          </div>
        </div>
      </Slide>
  );
}

export default LogisticScope;
