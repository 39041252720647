import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip
} from "reactstrap";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [navFontColor, setNavFontColor] = React.useState("text-success");
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
        setNavFontColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
        setNavFontColor("text-success");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="info">
        <Container>
          <div>
            <NavbarBrand
              href="https://www.e-fastcare.com"
              target="_self"
              id="navbar-brand"
              class="section-navbars"
            >
              <img src={require("assets/img/logo.png")} alt="E-FastCare" width="30%" height="30%"/>
            </NavbarBrand>

            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink
                  href="#PPE Supply"
                  onClick={e => {
                    e.preventDefault();
                    document
                      .getElementById("product-list")
                      .scrollIntoView();
                  }}
                >
                  <b className={navFontColor}>PPE Supply</b>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="#logistic-service"
                  onClick={e => {
                    e.preventDefault();
                    document
                      .getElementById("logistic-scope")
                      .scrollIntoView();
                  }}
                >
                  <b className={navFontColor}>Logistic Services</b>
                </NavLink>
              </NavItem>

                <NavItem>
                  <NavLink
                    href="#cutom-service"
                    onClick={e => {
                      e.preventDefault();
                      document
                        .getElementById("logistic-scope")
                        .scrollIntoView();
                    }}
                  >
                    <b className={navFontColor}>Customer Services</b>
                  </NavLink>
                </NavItem>

              <NavItem>
                <NavLink
                  href="#aboutus"
                  onClick={e => {
                    e.preventDefault();
                    document
                      .getElementById("aboutus")
                      .scrollIntoView();
                  }}
                >
                  <b className={navFontColor}>About Us</b>
                </NavLink>
              </NavItem>

            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
