import React from "react";


import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import KeyFeatures from "components/Sections/KeyFeatures.js";
import DynamicCounter from "components/Footers/DynamicCounter.js";
import AboutUs from "../components/Sections/AboutUs";
import LogisticIntro from "../components/Sections/LogisticIntro";
import LogisticScope from "../components/Sections/LogisticScope";
import ProductList from "../components/Sections/ProductList";
import CovidMap from "../components/Sections/CovidMap";
import SlantHeader from "../components/Headers/SlantHeader";


function Index() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar/>
      <SlantHeader/>
      <KeyFeatures/>
      <ProductList />
      <LogisticIntro/>
      <DynamicCounter/>
      <LogisticScope/>
      <CovidMap/>
      <AboutUs/>
      <DarkFooter/>

    </>
  );
}

export default Index;
