/*eslint-disable*/
import React from "react";
import Img1 from "assets/img/logistic_2.jpg";
import Img2 from "assets/img/logistic_3.jpg";
import Bounce from 'react-reveal/Bounce';

function LogisticIntro() {
  return (
      <Bounce>
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-12 text-center">
              <span className="caption d-block mb-2 font-secondary font-weight-bold">Connecting your supply chain to the global network</span>
              <h2 className="site-section-heading text-uppercase text-center font-secondary">Logistic Services</h2>
            </div>
          </div>
          <div className="row">
            <div className="site-half block">
              <div className="img-bg-1" style={{ backgroundImage: `url(${Img1})`}} ></div>
              <div className="container">
                <div className="row no-gutters align-items-stretch" data-aos="fade-up">
                  <div className="col-lg-5 ml-lg-auto py-5">
                    <span className="caption d-block mb-2 font-secondary font-weight-bold">Freight forwarding</span>
                    <p>Freight forwarding is in our DNA. Connecting the premium medical supplier to the world for over
                      20 years of providing quality logistics services ever since its incorporation. We stay connected
                      to our customers and committed to ensure the delivery keeps moving forward. With our expertise,
                      we act fast and deliver the medical supplier moving forward on the shortest time safely.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="site-half block">
              <div className="img-bg-1 right" style={{ backgroundImage: `url(${Img2})`}} ></div>
              <div className="container">
                <div className="row no-gutters align-items-stretch" data-aos="fade-up">
                  <div className="col-lg-5 mr-lg-auto py-5">
                    <span className="caption d-block mb-2 font-secondary font-weight-bold">end-to-end global supply chain</span>
                    <p>We support millions of products by our logistic services every year. We connect thousands of
                      suppliers with leading brands, airlines and sea shipping. We create customized, end-to-end
                      global supply chain and logistics services and solutions ranging from sourcing, quality control
                      and more. We move fast to deliver our promise of speed, quality and sustainability.</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </Bounce>
  );
}

export default LogisticIntro;
