import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

function AboutUs() {
  const [iconPills, setIconPills] = React.useState("1");
  const [pills, setPills] = React.useState("1");
  return (
      <>

        <div className="section section-tabs" id="aboutus">
          <div className="row mb-5">
            <div className="col-md-12 text-center">
              <h2 className="site-section-heading text-uppercase text-center font-secondary">About Us</h2>
            </div>
          </div>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="12" xl="12">
                <Card>
                  <CardHeader>
                    <Nav
                        className="nav-tabs-neutral justify-content-center"
                        data-background-color="blue"
                        role="tablist"
                        tabs
                    >
                      <NavItem>
                        <NavLink
                            className={pills === "1" ? "active" : ""}
                            href="#pablo"
                            onClick={e => {
                              e.preventDefault();
                              setPills("1");
                            }}
                        >
                          Overview
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                            className={pills === "2" ? "active" : ""}
                            href="#pablo"
                            onClick={e => {
                              e.preventDefault();
                              setPills("2");
                            }}
                        >
                          Responsibility
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                            className={pills === "3" ? "active" : ""}
                            href="#pablo"
                            onClick={e => {
                              e.preventDefault();
                              setPills("3");
                            }}
                        >
                          Supplement
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                            className={pills === "4" ? "active" : ""}
                            href="#pablo"
                            onClick={e => {
                              e.preventDefault();
                              setPills("4");
                            }}
                        >
                          Logistics
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardHeader>
                  <CardBody>
                    <TabContent
                        className="text-center"
                        activeTab={"pills" + pills}
                    >
                      <TabPane tabId="pills1">
                        <p>
                          E-FastCare is a global e-commerce platform to provide healthcare supply chain services globally.
                          The new dedicated e-commerce platform has been set up to meet the urgent volume requirements of
                          core PPE items for our existing and new customers at this unprecedented times.
                        </p>
                      </TabPane>
                      <TabPane tabId="pills2">
                        <p>
                          We are fully aware of the responsibility we bear toward our customers we served and closely monitor
                          the latest main principles and standards to guide us in our business dealings. Our commitment to quality
                          ensures the customers benefit from rigorously verified products whist typically delivering a significant
                          saving for global shipment across the world.
                        </p>
                      </TabPane>
                      <TabPane tabId="pills3">
                        <p>
                          We provide verified suppliers and quality management products to our customers and be responsible for global
                          logistics services with the support of numerous medical suppliers with a 20 years experiences global logistics service team.
                        </p>
                      </TabPane>
                      <TabPane tabId="pills4">
                        <p>
                          We develop the procurement and logistics services solutions which aim to save customers time and money in this pressured period.
                          The great quality control and fastest delivery will ensure you receive all urgent orders in a smooth running.
                        </p>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
  );
}

export default AboutUs;
