import React from "react";

// reactstrap components

import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";
import iconSet from "assets/fonts/icomoon/selection.json";
import IcomoonReact from "icomoon-react";

const items = [
  {
    src: require("assets/img/p1_n95.png"),
    caption: "NIOSH N95"
  },
  {
    src: require("assets/img/p2_surgical_mask.jpg"),
    caption: "3Q Surgical Mask"
  },
  {
    src: require("assets/img/p3_protection_suit.png"),
    caption: "Protection Suite"
  },
  {
    src: require("assets/img/p4_medical_surgical_mask.png"),
    caption: "Tianyi Medical Mask"
  }
];

const product_manual = require("assets/product_manual.pdf");

// core components

function ProductList() {

  return (
      <div className="site-section" id="product-list">
        <Container>
          <Row>
            <Col className="md-12 text-center">
              <span className="caption d-block mb-2 font-secondary font-weight-bold">Products &amp; Services</span>
              <h2 className="site-section-heading text-uppercase text-center font-secondary">Browse the healthcare
                equipment</h2>
            </Col>
          </Row>
          <Row>
            <Col className="md-12 nav-direction-white">
              <div className="media-image">
                <img src={items[0].src} alt="NIOSH N95" className="img-fluid" width="600" height="400" />
                  <div className="media-image-body">
                    <h4 className="font-secondary text-uppercase">{items[0].caption}</h4>
                    <Button className="btn-round" color="info" type="button">More</Button>
                  </div>
              </div>
            </Col>
            <Col className="md-12 nav-direction-white">
              <div className="media-image">
                <img src={items[1].src} alt="NIOSH N95" className="img-fluid" width="600" height="400" />
                <div className="media-image-body">
                  <h4 className="font-secondary text-uppercase">{items[1].caption}</h4>
                  <Button className="btn-round" color="info" type="button">More</Button>
                </div>
              </div>
            </Col>
            <Col className="md-12 nav-direction-white">
              <div className="media-image">
                <img src={items[2].src} alt="NIOSH N95" className="img-fluid" width="600" height="400" />
                <div className="media-image-body">
                  <h4 className="font-secondary text-uppercase">{items[2].caption}</h4>
                  <Button className="btn-round" color="info" type="button">More</Button>
                </div>
              </div>
            </Col>
            <Col className="md-12 nav-direction-white">
              <div className="media-image">
                <img src={items[3].src} alt="NIOSH N95" className="img-fluid" width="600" height="400" />
                <div className="media-image-body">
                  <h4 className="font-secondary text-uppercase">{items[3].caption}</h4>
                  <Button className="btn-round" color="info" type="button">More</Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="md-12 text-center">
              <Button className="btn-round" color="success" type="button" href={product_manual} target="_blank">
                <IcomoonReact iconSet={iconSet} color="white" size={15} icon="download" /> Download Product Manual
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
  );
}

export default ProductList;
