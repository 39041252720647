/*eslint-disable*/
import React from "react";

// reactstrap components
// core components
const bg = require("assets/img/bg12.jpg");
function SlantHeader() {

  return (
    <>
      <div className="slant-image-header">
        <div className="slant-image-header__bg" style ={ { backgroundImage: "url("+bg+")" } }></div>
        <h1 className="slant-image-header__title">Your healthcare Supplier</h1>
      </div>
    </>
  );
}

export default SlantHeader;
