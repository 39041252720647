import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

import iconSet from "assets/fonts/icomoon/selection.json";
import IcomoonReact, { iconList } from "icomoon-react";
import Fade from 'react-reveal/Fade';


function DarkFooter() {
  return (
    <>

      <div className="py-5 bg-primary">
         <div className="container">
           <div className="row align-items-center justify-content-center">
             <div className="col-md-6 text-center mb-3 mb-md-0">
               <Fade bottom>
                 <h2 className="text-uppercase text-white mb-4" data-aos="fade-up">We are looking forward to hearing from you</h2>
                 <a href="mailto:info@e-fastcare.com" className="btn bg-primary font-secondary text-uppercase">Contact Us</a>
               </Fade>
             </div>
           </div>
         </div>
      </div>


      <footer className="footer" data-background-color="black">
        <Container>
          <Row>
            <Col xs="8" lg="8">
              <Col xs="12" lg="12"><h4>About E-FastCare</h4></Col>
              <Col xs="12" lg="12">
                <Row>
                  <Col xs="12" lg="12">
                    <IcomoonReact iconSet={iconSet} color="#444" size={20} icon="map-marker" />&nbsp;
                    China Office: No 22, Guangji Building, Haishu District, NINGBO 315100
                  </Col>
                  <Col xs="12" lg="12">
                    <IcomoonReact iconSet={iconSet} color="#444" size={20} icon="map-marker" />&nbsp;
                    UK Office: 27 Old Gloucester Street LONDON WC1N 3AX
                  </Col>
                  <Col xs="12" lg="12">
                    <IcomoonReact iconSet={iconSet} color="#444" size={20} icon="phone" />&nbsp;
                    (+86) 22-120-9504
                  </Col>
                  <Col xs="12" lg="12">
                    <IcomoonReact iconSet={iconSet} color="#444" size={20} icon="mail_outline" />&nbsp;
                    <a href="mailto:info@e-fastcare.com"> info@e-fastcare.com</a>
                  </Col>
                  <Col xs="12" lg="12">
                    <IcomoonReact iconSet={iconSet} color="#444" size={20} icon="clock-o" />&nbsp;
                    Monday - Friday: 08:00 - 22:00, Saturday, Sunday: Closed
                  </Col>
                </Row>
              </Col>
            </Col>
            <Col xs="4" lg="4">
              {/*<Row>*/}
              {/*  <Col xs="12" lg="12"><h4>Social Icons</h4></Col>*/}
              {/*  <Col xs="12" lg="12">*/}
              {/*    <p>*/}
              {/*      <IcomoonReact iconSet={iconSet} color="#444" size={20} icon="facebook" />&nbsp;*/}
              {/*      <IcomoonReact iconSet={iconSet} color="#444" size={20} icon="linkedin" />&nbsp;*/}
              {/*      <IcomoonReact iconSet={iconSet} color="#444" size={20} icon="wechat" />*/}
              {/*    </p>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
            </Col>
          </Row>
          <div className="row pt-5 mt-5 text-center">
            <div className="col-md-12">
              <p>
                Copyright &copy;
                &nbsp;{(new Date().getFullYear())}&nbsp;
                All rights reserved | <a href="https://www.E-FastCare.com" >E-FastCare</a>
              </p>
            </div>
          </div>
        </Container>
      </footer>
    </>

  );
}

export default DarkFooter;
