/*eslint-disable*/
import React from "react";

// reactstrap components
import iconSet from "assets/fonts/icomoon/selection.json";
import IcomoonReact from "icomoon-react";
import Zoom from 'react-reveal/Zoom';
// core components

class KeyFeatures extends React.Component {

  render() {
    return (
        <>
            <div className="container">
                <div className="row mb-5">
                    <div className="col-md-12 text-center">
                        <span className="caption d-block mb-2 font-secondary font-weight-bold">Outstanding Services by</span>
                        <h2 className="site-section-heading text-uppercase text-center font-secondary">E-FastCare</h2>
                    </div>
                </div>
                <div className="row border-responsive">
                    <div className="col-md-6 col-lg-3 mb-4 mb-lg-0 border-right">
                        <Zoom>
                            <div className="text-center">
                                <IcomoonReact iconSet={iconSet} color="#71bc42" size={40} icon="globe" />
                                <h3 className="text-uppercase h4 mb-3">Global E-commerce</h3>
                                <p>An e-commerce platform to provide servies globally</p>
                            </div>
                        </Zoom>
                    </div>
                    <div className="col-md-6 col-lg-3 mb-4 mb-lg-0 border-right">
                        <Zoom>
                            <div className="text-center">
                                <IcomoonReact iconSet={iconSet} color="#71bc42" size={40} icon="stethoscope" />
                                <h3 className="text-uppercase h4 mb-3">Healthcare supplement</h3>
                                <p>Leading medical suppliers and market leaders to suit your requirement</p>
                            </div>
                        </Zoom>
                    </div>
                    <div className="col-md-6 col-lg-3 mb-4 mb-lg-0 border-right">
                        <Zoom>
                            <div className="text-center">
                                <IcomoonReact iconSet={iconSet} color="#71bc42" size={40} icon="local_shipping" />
                                <h3 className="text-uppercase h4 mb-3">Logistic Service Solutions</h3>
                                <p>Logistic services for global shipment across the world</p>
                            </div>
                        </Zoom>
                    </div>
                    <div className="col-md-6 col-lg-3 mb-4 mb-lg-0 border-right">
                        <Zoom>
                            <div className="text-center">
                                <IcomoonReact iconSet={iconSet} color="#71bc42" size={40} icon="heartbeat" />
                                <h3 className="text-uppercase h4 mb-3">Charity</h3>
                                <p>We shoulder the responsibility for a better society and world</p>
                            </div>
                        </Zoom>
                    </div>
                </div>
            </div>
        </>
    );
  }

}

export default KeyFeatures;
